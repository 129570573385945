import type { ComponentStyleConfig } from "@chakra-ui/theme";

const SectionRenderer: ComponentStyleConfig = {
	baseStyle: {
		jumpLinkWrapper: {
			position: "relative",
		},
		jumpLink: {
			fontWeight: "500",
			color: "white",
			bg: "gray.medium",
			position: "absolute",
			top: 4,
			right: 0,
			padding: 2,
			borderLeftRadius: "md",
		},
	},
};

export default SectionRenderer;
